// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-list-template-js": () => import("/opt/build/repo/src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datapage-js": () => import("/opt/build/repo/src/pages/datapage.js" /* webpackChunkName: "component---src-pages-datapage-js" */),
  "component---src-pages-formpage-js": () => import("/opt/build/repo/src/pages/formpage.js" /* webpackChunkName: "component---src-pages-formpage-js" */),
  "component---src-pages-imagetest-js": () => import("/opt/build/repo/src/pages/imagetest.js" /* webpackChunkName: "component---src-pages-imagetest-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loginpage-js": () => import("/opt/build/repo/src/pages/loginpage.js" /* webpackChunkName: "component---src-pages-loginpage-js" */),
  "component---src-pages-markdownpage-js": () => import("/opt/build/repo/src/pages/markdownpage.js" /* webpackChunkName: "component---src-pages-markdownpage-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

